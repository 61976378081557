import React, { useState } from 'react'
import t from 'prop-types'

import IcomoonReact from 'icomoon-react'
import iconSet from '../../images/icons/selection.json'

import styles from './collapse.module.css'

const Collapse = ({ title, text }) => {

    const [showText, setShowText] = useState(false);

    return (
        <div className={ styles.Wrapper } >
            <button onClick={ () => setShowText( !showText ) } style={ showText ? { boxShadow: '0 0 0.6rem #ccc' } : null } >
                <h4>{ title }</h4>
                <IcomoonReact iconSet={ iconSet } icon='arrow-down' alt='Ícone seta' className={ styles.ArrowIcon } style={ showText ? { transform: 'rotate(180deg)' } : null } />
            </button>
            {
                showText ?
                <div>
                    <p>{ text }</p>
                </div> :
                null
            }
        </div>
    )
}

Collapse.propTypes = {
    title: t.string,
    text: t.array,
}

Collapse.defaultProps = {
    title: 'Lorem ipsum dolor sit amet',
    text: `${<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>}`,
}

export default Collapse
