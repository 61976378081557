import React, { useState } from 'react'

import * as Yup from 'yup'
import axios from 'axios'

import styles from './form.module.css'
import ButtonStyle from '../Button/button.module.css'

import Input from './Input/Input'

const Form = () => {

  const [ sending, setSending ] = useState(false)
  const [ receivedResponse, setReceivedResponse ] = useState(null)

  const [ leadNameValidation, setLeadNameValidation ] = useState(false)
  const [ businessNameValidation, setBusinessNameValidation ] = useState(false)
  const [ businessSegmentValidation, setBusinessSegmentValidation ] = useState(false)
  // const [ businessLocationValidation, setBusinessLocationValidation ] = useState(false)
  const [ leadMailValidation, setLeadMailValidation ] = useState(false)
  const [ leadPhoneValidation, setLeadPhoneValidation ] = useState(false)

  async function validateForm (event) {
    event.preventDefault()

    setSending(true)

    setLeadNameValidation(false)
    setBusinessNameValidation(false)
    setBusinessSegmentValidation(false)
    // setBusinessLocationValidation(false)
    setLeadMailValidation(false)
    setLeadPhoneValidation(false)

    const data = {
      lead_name: event.target.lead_name.value,
      business_name: event.target.business_name.value,
      business_segment: event.target.business_segment.value,
      // business_location: event.target.business_location.value,
      lead_mail: event.target.lead_mail.value,
      lead_phone: event.target.lead_phone.value,
    }

    try {
      const validationSchema = Yup.object().shape({
        lead_name: Yup
          .string()
          .required()
          .min(3),
        business_name: Yup
          .string()
          .required()
          .min(3),
        business_segment: Yup
          .string()
          .required()
          .min(3),
        // business_location: Yup
        //   .string()
        //   .required()
        //   .min(3),
        lead_mail: Yup
          .string()
          .required()
          .email(),
        lead_phone: Yup
          .string()
          .matches(/[(]{1}[0-9]{2}[)]{1}[ ]{1}[0-9]{5}[-]{1}[0-9]{3,4}/g, 'O telefone deve ter 10 ou 11 números')
          .required()
      })

      await validationSchema.validate(data, {
        abortEarly: false
      })

    } catch (error) {
      error.inner.forEach(err => {
        switch(err.path) {
          case 'lead_name':
            setLeadNameValidation(true)
            break
          case 'business_name':
            setBusinessNameValidation(true)
            break
          case 'business_segment':
            setBusinessSegmentValidation(true)
            break
          // case 'business_location':
          //   setBusinessLocationValidation(true)
          //   break
          case 'lead_mail':
            setLeadMailValidation(true)
            break
          case 'lead_phone':
            setLeadPhoneValidation(true)
            break
          default: break
        }
      });
      return setSending(false)
    }

    await axios({
      method: 'post',
      url: 'https://hooks.zapier.com/hooks/catch/6219249/oz711u2/',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: {
        'Nome': data.lead_name,
        'Nome da Empresa': data.business_name,
        'Segmento da Empresa': data.business_segment,
        'Localização da Empresa': data.business_location,
        'E-Mail': data.lead_mail,
        'Telefone': data.lead_phone,
      },
    }).then((response => {
        if(response.status === 200) {
          setSending(false)
          return setReceivedResponse(true)
        } else {
          setSending(false)
          return setReceivedResponse(false)
        }
      }))
  }

  return (
    <>
      {
        receivedResponse ?
        <div className={ styles.PositiveFeedback } >
          <h4>Obrigado pelo contato!</h4>
          <p>Responderemos o mais cedo possível :)</p>
        </div>
        :
        <>
          <form onSubmit={ validateForm } id='faleConosco' className={ styles.Wrapper } >
            <Input name='lead_name' label='Seu nome' placeholder='Nome' invalid={ leadNameValidation } errorMessage='Este campo deve conter no mínimo 3 caracteres.' />
            <Input name='lead_mail' label='Seu e-mail' placeholder='seuemail@email.com' invalid={ leadMailValidation } errorMessage='Este não é um e-mail válido.' />
            <Input name='lead_phone' label='Seu telefone' placeholder='(27) 99999-9999' mask='(99) 99999-9999' invalid={ leadPhoneValidation } type='tel' errorMessage='O número deve conter entre 10 e 11 caracteres.' />
            <Input name='business_name' label='Nome do Negócio' placeholder='Kim Burger, Açai Praia, Bar do João, Japa Temaki, etc' invalid={ businessNameValidation } errorMessage='Este campo deve conter no mínimo 3 caracteres.' />
            <Input name='business_segment' label='Segmento' placeholder='Doces, Saladas, Italiana, Hamburgueria, etc' invalid={ businessSegmentValidation } errorMessage='Este campo deve conter no mínimo 3 caracteres.' />
          </form>
          <button type='submit' form='faleConosco' className={ ButtonStyle.Button + ' ' + ButtonStyle.Large } disabled={ sending } style={{ maxWidth: '350px' }} >{ sending ? 'Enviando...' : 'Enviar' }</button>
        </>
      }
    </>
  )
}

export default Form
