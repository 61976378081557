import React, { useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"

import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import SEO from '../components/seo'

import Layout from '../hoc/Layout/Layout'
import MainWrapper from '../hoc/MainWrapper/MainWrapper'

import Collapse from '../components/Collapse/Collapse'
import DashTitle from '../components/DashTitle/DashTitle'
import FormContato from '../components/Forms/FormContato'

const ContactPage = () => {

	const contactForm = useRef(null)
	// const scrollToContactForm = () => window.scrollTo({top: contactForm.current.offsetTop, behavior: 'smooth'})

  const contentfulData = useStaticQuery(graphql`
		query contentfulFaq {
			allContentfulFaqCard(sort: {fields: order}) {
				edges {
					node {
						title
						childContentfulFaqCardTextRichTextNode {
							json
						}
					}
				}
			}
		}
  `)

  const transformContentfulData = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <>{ children }<br className='HiddenMobile' /></>,
    },
    renderText: text => text.replace(/\s/g, ' ').replace('\n', <br className='HiddenMobile' />)
  }

  // Generates FAQ cards based on Contentful CMS
  const allFaqCards = () => {
		const cards = []

    contentfulData.allContentfulFaqCard.edges.map((node, index) => {
      const card = <Collapse
				title={ node.node.title }
				key={ index }
				text={ documentToReactComponents(node.node.childContentfulFaqCardTextRichTextNode.json, transformContentfulData) }
			/>

      return cards.push(card)
    })

    return cards
  }

	return (
		<MainWrapper>
			<SEO title="Contato" />
			<Layout outerContainer={{ paddingTop: '5rem' }} reference='faq' >
				<DashTitle>Perguntas Frequentes</DashTitle>
				{ allFaqCards() }
			</Layout>
			<div ref={contactForm}>
				<Layout reference='formulario-de-contato'>
					<DashTitle>Contato</DashTitle>
					<FormContato />
				</Layout>
			</div>
		</MainWrapper>
	)
}

export default ContactPage
